/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities; 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-open {
  z-index: 20000
}

.ant-modal {
  border-radius: 20px;
  /* Only border-radius here */
}

.ant-modal-content {
  border-radius: 20px;
  /* border: 10px solid black; */
}
